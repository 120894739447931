import React from "react";
import Answer from "./Answer";

function Question(props) {
    let {questionData, questionIndex, quizPosition} = props;
    let {question, weight, answers} = questionData;
    let visibility = 'hidden';

    function onPutAnswer() {
        props.updateQuizPosition();
    }

    if (quizPosition == questionIndex) {
        visibility = 'show';
    }

    return (
        <div className={`question-item ${visibility}`}>
            <div className="question">
                <p>{question}</p>
            </div>
            <div className="answers">
                {answers.map((answer, index) =>
                    <Answer
                        answerData={answer}
                        weight={weight}
                        key={index}
                        questionIndex={questionIndex}
                        onPutAnswer={onPutAnswer}
                    />
                )}
            </div>
        </div>
    )
}

export default Question;