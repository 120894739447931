import React, {useEffect, useRef, useState} from "react";
import axios, {formToJSON} from "axios";
import Question from "./Question";

function Quiz(props) {
    const [isLoading, setIsLoading] = useState();
    const [questionsData, setQuestionsData] = useState();
    const [quizPosition, setQuizPosition] = useState(0);
    const [result, setResult] = useState(false);
    //const [formData, setFormData] = useState([]);
    let formData = useRef();

    useEffect(() => {
        getQuestions();
    }, []);

    function getQuestions() {
        setIsLoading(true);
        axios.get(`${process.env.REACT_APP_API_DOMAIN}/wp-json/sb-api/v1/stilfinder`)
            .then(response => response.data)
            .then((data) => {
                setQuestionsData(data);
                setIsLoading(false);
            });
    }

    function updateQuizPosition() {
        setQuizPosition(quizPosition + 1);

        if ((quizPosition + 1) === questionsData.dataLength) {
            formData.current.requestSubmit();
        }
    }

    function handleSubmit(event) {
        event.preventDefault();
        props.returnResult(formToJSON(event.target));
        setResult(true);
    }

    function goBack() {
        setQuizPosition(quizPosition - 1);
    }

    function BackLink() {
        if (quizPosition > 0) {
            return (
                <div className="link back-link" onClick={goBack}>
                    <i className="icon icon-arrow-left"></i> <span>zurück</span>
                </div>
            )
        }
    }

    if (isLoading === false && result === false) {
        return (
            <div className="quiz">
                <form ref={formData} onSubmit={handleSubmit}>
                    {Object.values(questionsData.data).map((question, index) =>
                        <Question
                            key={index}
                            questionData={question}
                            questionIndex={index}
                            quizPosition={quizPosition}
                            updateQuizPosition={updateQuizPosition}
                        />
                    )}
                </form>
                <div className="quiz-footer">
                    <div className="row">
                        <div className="col-md-6">
                            <BackLink/>
                        </div>
                        <div className="col-md-6">
                            <span
                                className="text-end d-block">Frage {quizPosition + 1} von {questionsData.dataLength}</span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Quiz;