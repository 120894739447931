import {Link} from "react-router-dom";
import {decode} from "html-entities";
import React from "react";
import Video from "../Elements/Video";

function ThemeNavElement(props) {
    const {themeData} = props;
    const {title, image_small, video_small} = themeData;
    const themeId = props.themeId;
    let stateClass = 'inactive';
    let videoPlay = false;

    if (themeId == themeData.id) {
        stateClass = 'active';
    }

    function playVideo(e) {
        let video = e.currentTarget.querySelector('video');
        if (video && videoPlay === false) {
            video.play().then(() =>
                videoPlay = true
            );
        }
    }

    function stopVideo(e) {
        let video = e.currentTarget.querySelector('video');
        if (video && videoPlay === true) {
            video.pause();
            videoPlay = false;
        }
    }

    return (
        <div
            className={`shortcut-nav-item theme-nav-item ${stateClass}`}
            themeid={themeData.id}>
            <Link
                to={`/themenwelten/${themeData.id}/`}
                onMouseOver={playVideo}
                onMouseOut={stopVideo}
            >
                <div className="shortcut-nav-item-title theme-nav-item-title">
                    <div className="shortcut-nav-item-title-inner">
                                    <span className="main-nav-name">
                                        {decode(themeData.title)}
                                    </span>
                    </div>
                </div>
                <div className="shortcut-nav-item-img theme-nav-item-img">
                    <Video themeData={themeData}/>
                </div>
            </Link>
        </div>
    )
}

export default ThemeNavElement;