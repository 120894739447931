import React from 'react';
import {Link, NavLink} from "react-router-dom";
import ThemesNav from "../Themes/ThemesNav";
import Logo from './../../assets/img/logo.svg';

function Navigation() {
    return (
        <div className="navigation">
            <div className="main-navigation">
                <Link to="/" className="logo d-none d-xl-block">
                    <img src={Logo} alt="Destag Stilberater Logo"/>
                </Link>
                <div className="main-nav-item">
                    <NavLink to="/" end>
                        <i className="icon icon-shape-star"></i>
                        <span className="main-nav-name">
                            Aktuelle Trends
                        </span>
                    </NavLink>
                </div>
                <div className="main-nav-item">
                    <NavLink to="/stilfinder">
                        <i className="icon icon-f-chat"></i>
                        <span className="main-nav-name">
                            Stilfinder
                        </span>
                    </NavLink>
                </div>
                <div className="main-nav-item">
                    <NavLink to="/themenwelten">
                        <i className="icon icon-forest"></i>
                        <span className="main-nav-name">
                            Themenwelten
                        </span>
                    </NavLink>
                </div>
                <div className="main-nav-item">
                    <NavLink to="/kombinationen">
                        <i className="icon icon-microsoft"></i>
                        <span className="main-nav-name">
                            Alle Kombinationen
                        </span>
                    </NavLink>
                </div>
            </div>
            <div className="shortcuts">
                <div className="shortcuts-title">
                    <span className="main-nav-name">
                        Schnellzugriff
                    </span>
                </div>
                <ThemesNav/>
            </div>
        </div>
    );
}

export default Navigation;