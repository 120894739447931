import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {decode} from 'html-entities';
import axios from "axios";
import Audio from "../Elements/Audio";

function Material() {
    const navigate = useNavigate();
    const location = useLocation();
    let {themeId, materialId} = useParams();
    const [materialData, setMaterialData] = useState([])
    const [themeData, setThemeData] = useState([])
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        getMaterial();
        getTheme();
    }, [themeId])

    function getMaterial() {
        axios.get(`${process.env.REACT_APP_API_DOMAIN}/wp-json/sb-api/v1/material/${materialId}`)
            .then(response => response.data)
            .then((data) => {
                setMaterialData(data);
                setIsLoading(false)
            });
    }

    function getTheme() {
        axios.get(`${process.env.REACT_APP_API_DOMAIN}/wp-json/sb-api/v1/theme/${themeId}`)
            .then(response => response.data)
            .then((data) => {
                setThemeData(data)
            });
    }

    function Video() {
        if (themeData.video) {
            return (
                <div className="material-hero-img hero-img">
                    <video autoPlay loop muted playsInline poster={themeData.image}>
                        <source src={themeData.video}/>
                        Your browser does not support the video tag.
                    </video>
                </div>
            )
        }
    }

    function goToReferrer() {
        let backLink = `/themenwelten/${themeId}`;

        if (location.state) {
            backLink = location.state.prevPath;
        }

        navigate(backLink);
    }

    if (isLoading === false) {
        return (
            <div className="material">
                <Video/>
                <div className="container">
                    <div className="material-intro intro intro-bg mb-8">
                        <Audio audioSrc={themeData.audio}/>
                        <h2 className="h2">{decode(materialData.title)}</h2>
                        <div className="intro-text">
                            <p>{decode(materialData.excerpt)}</p>
                        </div>
                        <div
                            className="link back-link"
                            onClick={goToReferrer}
                        >
                            <i className="icon icon-arrow-left"></i> <span>Zurück zur Übersicht</span>
                        </div>
                    </div>
                    <div className="gallery">
                        {materialData.gallery.map(galleryImage =>
                            <div className="gallery-item" key={galleryImage.id}>
                                <img src={galleryImage.src} alt={decode(galleryImage.alt)}/>
                            </div>
                        )}
                    </div>
                    <div className="text-block card">
                        <div className="text-block-inner text-center">
                            <div className="text-block-icon">
                                <i className="icon icon-virtual-reality"></i>
                            </div>
                            <h2>Jetzt in 3D anschauen</h2>
                            {/*Todo: replace text with translation file*/}
                            <p>
                                Öffnen Sie Ihre Materialauswahl direkt in unserem 3D-Konfigurator.
                            </p>
                            <a
                                href={materialData.link}
                                className="btn btn-secondary"
                                target="_blank"
                            >Im 3D-Konfigurator öffnen</a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Material;