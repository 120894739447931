import {isMobile} from "react-device-detect";
import {decode} from "html-entities";
import React from "react";

function Video(props) {
    const {title, image_small, video_small} = props.themeData;

    if (!isMobile && video_small) {
        return (
            <video loop muted poster={image_small}>
                <source src={video_small}/>
                {/*video fallback*/}
                <img
                    src={image_small}
                    alt={decode(title)}
                />
            </video>
        )
    } else {
        return (
            <img
                src={image_small}
                alt={decode(title)}
            />
        )
    }
}

export default Video;