import React, {useEffect, useState} from 'react';
import axios from "axios";
import {decode} from 'html-entities';
import InfiniteScroll from 'react-infinite-scroll-component';
import MaterialListItem from "../Materials/MaterialListItems";
import Loader from "../../assets/img/loader.svg";

function Trends() {
    const termId = 35;
    const [trendData, setTrendData] = useState([]);
    const [trendsMaterialData, setTrendsMaterialData] = useState([])
    const [isLoading, setIsLoading] = useState(true);
    const [hasMoreData, setHasMoreData] = useState(true);
    const itemsPerPage = 3;
    const [maxItems, setMaxItems] = useState([]);
    const [page, setPage] = useState(1);

    useEffect(() => {
        getTrend();
    }, [])

    function getTrend() {
        setIsLoading(true);
        axios.get(`${process.env.REACT_APP_API_DOMAIN}/wp-json/wp/v2/sb_tags/${termId}`)
            .then(response => response.data)
            .then((data) => {
                setTrendData(data);
                getTrendMaterials();
            });
    }

    function getTrendMaterials() {
        setIsLoading(true);
        axios.get(`${process.env.REACT_APP_API_DOMAIN}/wp-json/sb-api/v1/trends?&per_page=${itemsPerPage}`)
            .then(response => response.data)
            .then((data) => {
                setMaxItems(data.dataLength);
                setTrendsMaterialData(data.data);
                setPage(page + 1);
                setIsLoading(false);
            });
    }

    // get data for infinite scrolling
    function fetchData() {
        if (trendsMaterialData.length >= maxItems) {
            setHasMoreData(false);
            return;
        }
        setTimeout(() => {
            axios.get(`${process.env.REACT_APP_API_DOMAIN}/wp-json/sb-api/v1/trends?&per_page=${itemsPerPage}&page=${page}`)
                .then(response => response.data)
                .then((data) => {
                    setTrendsMaterialData(trendsMaterialData.concat(data.data));
                    setPage(page + 1);
                });
        }, 200);
    }

    const {name, description} = trendData;

    if (isLoading === false) {
        return (
            <div className="trends">
                <div className="container">
                    <div className="trends-intro intro">
                        <h1>{decode(name)}</h1>
                        <div className="intro-text">
                            <p>{decode(description)}</p>
                        </div>
                    </div>
                    <InfiniteScroll
                        dataLength={trendsMaterialData.length}
                        next={fetchData}
                        className="materials"
                        hasMore={hasMoreData}
                        loader={<div className="loader"><img src={Loader} alt="Laden"/></div>}
                    >
                        {Object.values(trendsMaterialData).map(material =>
                            <MaterialListItem key={material.id} material={material}/>
                        )}
                    </InfiniteScroll>
                </div>
            </div>
        )
    }
}

export default Trends;