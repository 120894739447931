import React, {useState} from 'react';
import Logo from './../../assets/img/logo.svg';
import {Link} from "react-router-dom";

function Header(props) {
    let {updateNavStatus, navStatus} = props;
    let [status, setStatus] = useState(navStatus);

    React.useEffect(() => {
        setStatus(navStatus)
    }, [props])

    function toggleStatus() {
        if (status === 'inactive') {
            updateNavStatus('active')
        } else {
            updateNavStatus('inactive')
        }
    }

    return (
        <div className="header-wrapper d-block d-xl-none">
            <div className="container">
                <header className="header">
                    <div className="header-inner">
                        <div className="logo">
                            <Link to="/" className="logo">
                                <img src={Logo} alt="Destag Stilberater Logo" width={100} height={37}/>
                            </Link>
                        </div>
                        <div
                            className={`menu-toggle ${status}`}
                            onClick={toggleStatus}
                        >
                            <div className="burger" role="button" button-name="Navigation">
                                <div className="burger-line burger-line-1"></div>
                                <div className="burger-line burger-line-2"></div>
                                <div className="burger-line burger-line-3"></div>
                            </div>
                        </div>
                    </div>
                </header>
            </div>
        </div>
    )
}

export default Header;