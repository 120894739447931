import React, {useEffect, useState} from 'react'
import axios from 'axios';
import {Link, useLocation, useParams} from "react-router-dom";
import ThemeNavElement from "./ThemeNavElement";
import neusteBg from "../../assets/img/neuste_bg.jpg"

function ThemesNav() {
    let {themeId} = useParams();
    let stateClass = 'inactive';
    const {pathname} = useLocation();
    const [isLoading, setIsLoading] = useState(true);
    const [themesData, setThemesData] = useState([]);

    if (pathname === '/neuste') {
        stateClass = 'active';
    }

    useEffect(() => {
        getThemes();
    }, [])

    function getThemes() {
        setIsLoading(true);
        axios.get(`${process.env.REACT_APP_API_DOMAIN}/wp-json/sb-api/v1/themes`)
            .then(response => response.data)
            .then((data) => {
                setThemesData(data);
                setIsLoading(false);
            });
    }

    if (isLoading === false) {
        return (
            <div className="shortcut-nav theme-nav">
                <div className={`shortcut-nav-item theme-nav-item ${stateClass}`}>
                    <Link to="/neuste">
                        <div className="shortcut-nav-item-title theme-nav-item-title">
                            <div className="shortcut-nav-item-title-inner">
                                <span className="main-nav-name">Neuste</span>
                            </div>
                        </div>
                        <div className="shortcut-nav-item-img theme-nav-item-img">
                            <img src={neusteBg} alt="Neuste"/>
                        </div>
                    </Link>
                </div>
                {themesData.map(theme =>
                    <ThemeNavElement themeData={theme} themeId={themeId} key={theme.id}/>
                )}
            </div>
        );
    }
}

export default ThemesNav;