import './App.scss';
import {Route, Routes} from 'react-router-dom';
import React from 'react';
import Trends from "./components/Trends/Trends";
import Page from "./components/Layout/Page";
import ThemesList from "./components/Themes/ThemesList";
import Material from "./components/Materials/Material";
import AllMaterials from "./components/Themes/AllMaterials";
import Theme from "./components/Themes/Theme";
import Stilfinder from "./components/Stilfinder/Stilfinder";
import NotFound from "./components/Layout/NotFound";
import New from "./components/Themes/New";

function App() {
    return (
        <Routes>
            <Route path="/" element={<Page/>}>
                <Route path='*' element={<NotFound/>}/>
                <Route index element={<Trends/>}/>
                <Route path="kombinationen" element={<AllMaterials/>}/>
                <Route path="themenwelten">
                    <Route index element={<ThemesList/>}/>
                    <Route path=":themeId" element={<Theme/>}/>
                    <Route path=":themeId/material/:materialId" element={<Material/>}/>
                </Route>
                <Route path="stilfinder" element={<Stilfinder/>}/>
                <Route path="neuste" element={<New/>}/>
            </Route>
        </Routes>
    );
}

export default App;
