import axios from "axios";
import React, {useEffect, useState} from "react";
import Filter from './../../assets/img/filter.svg';
import {decode} from "html-entities";
import {useNavigate, useParams} from "react-router-dom";

function ThemeSelect(props) {
    let {themeId} = useParams();
    let navigate = useNavigate();
    const defaultThemeId = process.env.REACT_APP_PARENT_THEME_ID;
    const [themesData, setThemesData] = useState([]);
    const [filterImage, setFilterImage] = useState(Filter);
    const [themeSelectVisibility, setThemeSelectVisibility] = useState('hidden');
    const [themeSelectStatus, setThemeSelectStatus] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        getThemes();
    }, [themeId]);

    // get all themes
    function getThemes() {
        axios.get(`${process.env.REACT_APP_API_DOMAIN}/wp-json/sb-api/v1/themes/`)
            .then(response => response.data)
            .then((data) => {
                setThemesData(data);
                preSelectThemeFilter(data);
            });
    }

    // toggle theme select status
    function toggleThemeSelect() {
        let showState = (themeSelectVisibility === "hidden") ? "show" : "hidden";
        document.getElementById('overlay').classList.toggle('show');
        setThemeSelectVisibility(showState);
    }

    // set default selection
    function preSelectThemeFilter(data) {
        if (themeId !== process.env.REACT_APP_PARENT_THEME_ID) {
            data.map(theme => {
                if (theme.id == themeId) {
                    setFilterImage(theme.image);
                    setThemeSelectStatus(true);
                }
            })
        }
        setIsLoading(false);
    }

    // set theme filter
    function themeFilter(themeProps) {
        navigate(`/themenwelten/${themeProps.id}`);
        setFilterImage(themeProps.image);
        props.handleThemeFilter(themeProps.id);
        document.getElementById('overlay').classList.remove('show');
        setThemeSelectVisibility("hidden");
        setThemeSelectStatus(true);
    }

    function FilterImage() {
        return (
            <img src={filterImage} alt="Filter"/>
        )
    }

    // remove theme filter button, if a theme was selected
    function RemoveThemeFilterButton() {
        if (themeSelectStatus === true) {
            return (
                <div
                    className="remove-theme-filter-image"
                    onClick={removeThemeFilter}
                >
                    <i className="icon icon-f-remove"></i>
                </div>
            );
        }
    }

    // remove filter and reset data
    function removeThemeFilter() {
        setFilterImage(Filter);
        props.handleThemeFilter(defaultThemeId);
        setThemeSelectStatus(false);
        navigate("/kombinationen");
    }

    if (isLoading === false) {
        return (
            <div className="theme-select-wrapper">
                <div className={`toggle-theme-select-wrapper ${themeSelectStatus}`}>
                    <div
                        className="toggle-theme-select"
                        onClick={toggleThemeSelect}
                    >
                        <FilterImage/>
                    </div>
                    <RemoveThemeFilterButton/>
                </div>
                <div className={`theme-select ${themeSelectVisibility}`}>
                    <div className="theme-select-inner">
                        <p className="h3">Themenwelten</p>
                        <span
                            className="close-theme-select"
                            onClick={toggleThemeSelect}
                        >
                            <i className="icon icon-d-remove"></i>
                        </span>
                        <div className="row g-4">
                            {themesData.map(theme =>
                                <div
                                    className="theme-select-theme col-lg-3 col-sm-4 col-6"
                                    key={theme.id}
                                    onClick={() => themeFilter(theme)}
                                >
                                    <div className="theme-select-title">
                                        <div className="theme-select-title-inner">
                                            {decode(theme.title)}
                                        </div>
                                    </div>
                                    <div className="theme-select-img">
                                        {theme.image.length > 0 &&
                                        <img src={theme.image} alt={theme.title}/>
                                        }
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ThemeSelect;