import {withCookies} from "react-cookie";

function Cookie(props) {
    const {cookies} = props;

    /*if (process.env.NODE_ENV !== 'development' && cookies.get('borlabs-cookie') === undefined) {
        window.location.href = process.env.REACT_APP_API_DOMAIN + '?referrer=app&path=' + window.location.href;
    }*/
}

export default withCookies(Cookie);