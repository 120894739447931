import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter as Router} from "react-router-dom";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import ScrollToTop from "./components/Elements/ScrollToTop";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Router>
        <ScrollToTop/>
        <App/>
    </Router>
);

serviceWorkerRegistration.register();
//reportWebVitals(console.log);
