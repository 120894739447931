import React, {useEffect, useState} from 'react';
import {Outlet, useLocation} from 'react-router-dom';
import Footer from "./Footer";
import Navigation from "./Navigation";
import Header from "./Header";
import Cookie from "../Elements/Cookie";

function Page() {
    const location = useLocation()
    const [navStatus, setNavStatus] = useState('inactive');
    const [scrollButtonClass, setScrollButtonClass] = useState('hidden');

    useEffect(() => {
        setNavStatus('inactive');
        window.addEventListener('scroll', handleScroll);
    }, [location])

    function updateNavStatus(status) {
        setNavStatus(status);
    }

    function scrollToTop() {
        window.scrollTo(0, 0);
    }

    function handleScroll() {
        if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
            setScrollButtonClass('show')
        } else {
            setScrollButtonClass('hidden')
        }
    }

    return (
        <div className="app-wrapper">
            <div className={`app-page-wrapper ${navStatus}`}>
                <Header updateNavStatus={updateNavStatus} navStatus={navStatus}/>
                <div className="clearfix"></div>
                <Navigation/>
                <div className="content">
                    <div className="content-wrapper">
                        <div className="content-inner">
                            <Outlet/>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
            <div id="overlay" className={navStatus}/>
            <Cookie/>
            <button
                className={`${scrollButtonClass} scroll-top-button`}
                aria-label="Zum Anfang scrollen"
                title="Zum Anfang scrollen"
                onClick={scrollToTop}
            >
                <i className="icon icon-arrow-left"></i>
            </button>
        </div>
    );
}

export default Page;