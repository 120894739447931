import {useEffect, useState} from "react";
import axios from "axios";
import ThemesItem from "../Themes/ThemesItem";

function Result(props) {
    let {result, resultFormData} = props;
    const [isLoading, setIsLoading] = useState(true);
    const [resultData, setResultData] = useState();
    const [themeId, setThemeId] = useState();

    useEffect(() => {
        evaluateResult();
        getResultData();

    }, [themeId]);

    /**
     * get the theme result based on the selected answers
     */
    function evaluateResult() {
        if (result) {
            // sort array by length, to get highest answer with highest weight
            // TODO: when id length is higher than 2, function will not work! Better: count array comma separated elements
            const maxWeightId = Object.values(resultFormData).sort((a, b) => b.length - a.length)[0].split(',')[0];

            // convert object into a single depth array
            const resultArray = Object.values(resultFormData).join(',').split(',');
            const count = [];

            // count same answer values
            resultArray.forEach(element => {
                count[element] = (count[element] || 0) + 1;
            });

            // get the ids of most selected answer
            let arr = Object.values(count);
            let max = Math.max(...arr);
            let resultIds = [];

            count.forEach((element, index) => {
                if (element === max) {
                    resultIds.push(index);
                }
            });

            // check if there are multiple results and set the highest weighted answer as the final result if it is part of the most selected answers
            if (resultIds.length > 1 && resultIds.includes(+maxWeightId)) {
                setThemeId(maxWeightId);
            } else {
                setThemeId(resultIds[0]);
            }
        }
    }

    function getResultData() {
        if (themeId) {
            axios.get(`${process.env.REACT_APP_API_DOMAIN}/wp-json/sb-api/v1/stilfinder-result?id=${themeId}`)
                .then(response => response.data)
                .then((data) => {
                    setResultData(data);
                    setIsLoading(false);
                });
        }
    }

    if (isLoading === false) {
        return (
            <div className="theme-list stilfinder-result">
                <p>Folgende Themenwelt passt zu Ihren Angaben:</p>
                <ThemesItem key={resultData.id} themeData={resultData}/>
            </div>
        )
    }
}

export default Result;