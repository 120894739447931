import React, {useState} from "react";
import Result from "./Result";
import Quiz from "./Quiz";

function Stilfinder() {
    const [selectedAnswers] = useState([]);
    const [questionKey, setQuestionKey] = useState(0);
    const [questionData, setQuestionData] = useState();
    const [result, setResult] = useState(false);
    const [resultFormData, setResultFormData] = useState([]);
    const [isLoading, setIsLoading] = useState();

    function returnResult(formData) {
        setResult(true);
        setResultFormData(formData);
    }

    function ShowResult() {
        if (result === true) {
            return (
                <Result result={result} resultFormData={resultFormData}/>
            )
        }
    }

    return (
        <div className="stilfinder">
            <div className="container">
                <div className="stilfinder-intro intro">
                    <h1>Stilfinder</h1>
                    <div className="intro-text">
                        <p>Lassen Sie sich von uns beraten. Beantworten Sie uns einfach einige Fragen zum Vorstorbenen
                            und erhalten Sie in wenigen Minuten eine maßgeschneiderte Empfehlung.</p>
                    </div>
                </div>
                <Quiz returnResult={returnResult}/>
                <ShowResult/>
            </div>
        </div>
    )
}

export default Stilfinder;