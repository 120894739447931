import React, {useEffect, useState} from 'react'
import {decode} from 'html-entities';
import {Link, useLocation} from "react-router-dom";
import {LazyLoadImage} from "react-lazy-load-image-component";

function MaterialListItem(props) {
    const location = useLocation();
    let {material, themeId} = props;
    const {id, title, theme, excerpt, image, materials} = material;
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(false);
    }, [])

    function MaterialTags() {
        return (
            <div className="material-tags">
                {materials.map(materialTag =>
                    <div className="material-tag" key={materialTag.id}>
                        <img src={materialTag.image} alt={materialTag.name}/>
                    </div>
                )}
            </div>
        )
    }

    if (isLoading === false) {
        let themeRef = theme;
        if (themeId && themeId !== process.env.REACT_APP_PARENT_THEME_ID) {
            themeRef = themeId;
        }

        const link = `/themenwelten/${themeRef}/material/${id}`;

        return (
            <div className="card trends-card material-item">
                <div className="material-img">
                    <Link to={link} state={{prevPath: location.pathname}}>
                        {image &&
                            <LazyLoadImage
                                src={image}
                                effect="blur"
                                alt={decode(title)}
                            />
                        }
                    </Link>
                </div>
                <div className="material-teaser">
                    <h2 className="h3">{decode(title)}</h2>
                    <div className="material-teaser-text">
                        <p>{decode(excerpt)}</p>
                    </div>
                    <div className="card-footer">
                        <Link to={link} state={{prevPath: location.pathname}}>
                            <button className="btn btn-outline-primary">
                                Kombination ansehen
                            </button>
                        </Link>
                        <MaterialTags/>
                    </div>
                </div>
            </div>
        )
    }
}

export default MaterialListItem;