function Answer(props) {
    let {answerData, weight, questionIndex} = props;
    let answerValue = answerData.sf_answer_relation.join(',');

    if (weight > 1) {
        for (let i = 1; i < weight; i++) {
            answerValue = answerValue + ',' + answerData.sf_answer_relation.join(',')
        }
    }

    async function putAnswer(event) {
        if (event.target.querySelector('input')) {
            event.target.querySelector('input').checked = true;
            await new Promise(resolve => setTimeout(resolve, 300));
            props.onPutAnswer();
        }
    }

    return (
        <div className="answer" onClick={putAnswer}>
            <input type="radio" value={answerValue} name={`question_${questionIndex}`}/>
            <span className="answer-text">{answerData.sf_answer_text}</span>
        </div>
    );
}

export default Answer;