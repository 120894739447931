import {Link} from "react-router-dom";

function NotFound() {
    return (
        <div className="container">
            <div className="intro text-center">
                <h1>404</h1>
                <div className="intro-text">
                    <p>Die Seite konnte nicht gefunden werden.</p>
                    <Link to='/'>
                        <span className="btn btn-outline-secondary">Startseite</span>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default NotFound;