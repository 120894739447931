import React, {useEffect, useState} from 'react'
import axios from 'axios';
import ThemesItem from "./ThemesItem";
import InfiniteScroll from 'react-infinite-scroll-component';
import Loader from "../../assets/img/loader.svg";

function ThemesList() {
    const [themesData, setThemesData] = useState([])
    const [hasMoreData, setHasMoreData] = useState(true);
    const itemsPerPage = 6;
    const [maxItems, setMaxItems] = useState();
    const [page, setPage] = useState(1);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        getThemes();
    }, []);

    function getThemes() {
        axios.get(`${process.env.REACT_APP_API_DOMAIN}/wp-json/sb-api/v1/themes-list?per_page=${itemsPerPage}&page=${page}`)
            .then(response => response.data)
            .then((data) => {
                setThemesData(data.data);
                setMaxItems(data.dataLength);
                setPage(page + 1);
                setIsLoading(false);
            });
    }

    // get data for infinite scrolling
    function fetchData() {
        if(themesData.length >= maxItems) {
            setHasMoreData(false);
            return;
        }
        setTimeout(() => {
            axios.get(`${process.env.REACT_APP_API_DOMAIN}/wp-json/sb-api/v1/themes-list?per_page=${itemsPerPage}&page=${page}`)
                .then(response => response.data)
                .then((data) => {
                    setThemesData(themesData.concat(data.data));
                    setPage(page + 1);
                });
        }, 100);
    }

    if (isLoading === false) {
        return (
            <div className="theme-list">
                <div className="intro-wrapper">
                    <div className="container">
                        <div className="theme-intro intro">
                            <h1>Unsere Themenwelten</h1>
                            <div className="intro-text">
                                <p>Finden Sie die passende Materialkombination mit Hilfe unserer Themenwelten.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <InfiniteScroll
                        dataLength={themesData.length}
                        next={fetchData}
                        className="row row-cols-lg-2 g-5"
                        hasMore={hasMoreData}
                        loader={<div className="loader"><img src={Loader} alt="Laden"/></div>}
                    >
                        {Object.values(themesData).map(theme =>
                            <ThemesItem key={theme.id} themeData={theme}/>
                        )}
                    </InfiniteScroll>
                </div>
            </div>
        );
    }
}

export default ThemesList;