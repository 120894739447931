import React from 'react';
import { ReactComponent as TikTok } from './../../assets/img/tiktok.svg';
import { ReactComponent as Pinterest } from './../../assets/img/pinterest.svg';
import { ReactComponent as YouTube } from './../../assets/img/youtube.svg';

function Footer() {
    return (
        <div className="footer-wrapper">
            <footer className="footer">
                <div className="container">
                    <ul className="footer-nav">

                        <li>
                            <a href="https://form.asana.com/?k=GzsB0-fHdB2YpRa0eV53Fw&d=1200596449153061"
                               target="_blank" rel="noopener noreferrer">
                                Feedback
                            </a>
                        </li>
                        <li>
                            <a href={`${process.env.REACT_APP_API_DOMAIN}/impressum`} target="_blank"
                               rel="noopener noreferrer">
                                Impressum
                            </a>
                        </li>
                        <li>
                            <a href={`${process.env.REACT_APP_API_DOMAIN}/datenschutz`} target="_blank"
                               rel="noopener noreferrer">
                                Datenschutz
                            </a>
                        </li>
                        <ul className="footer-nav-social">
                            <li>
                                <a
                                    href="https://www.instagram.com/destaggrabsteine/?hl=de"
                                    target="_blank" rel="noopener noreferrer"
                                    aria-label="Instagram"
                                >
                                    <i className="icon icon-logo-instagram"></i>
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://www.facebook.com/pages/category/Wholesale---Supply-Store/Destag-Natursteinwerk-GmbH-100273125516297/"
                                    target="_blank" rel="noopener noreferrer"
                                    aria-label="Facebook"
                                >
                                    <i className="icon icon-logo-facebook"></i>
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://www.tiktok.com/@destagnatursteine?_t=8djb2vRaCtf&_r=1"
                                    target="_blank" rel="noopener noreferrer"
                                    aria-label="TikTok"
                                >
                                    <TikTok fill="currentColor" /></a>
                            </li>
                            <li>
                                <a
                                    href="https://www.pinterest.de/DestagGrabsteine/"
                                    target="_blank" rel="noopener noreferrer"
                                    aria-label="Pinterest"
                                >
                                    <Pinterest fill="currentColor" /></a>
                            </li>
                            <li>
                                <a
                                    href="https://www.youtube.com/channel/UCEHuiEMrPLqZ9KtfMfcv7oQ"
                                    target="_blank" rel="noopener noreferrer"
                                    aria-label="YouTube"
                                >
                                    <YouTube fill="currentColor" /></a>
                            </li>
                        </ul>
                    </ul>
                </div>
            </footer>
        </div>
    );
}

export default Footer;