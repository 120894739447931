import React from 'react'
import {decode} from "html-entities";
import {Link} from "react-router-dom";
import {LazyLoadImage} from "react-lazy-load-image-component";
import {isMobile} from "react-device-detect";
import 'react-lazy-load-image-component/src/effects/blur.css';

function ThemesItem(props) {
    let {themeData} = props;
    let {title, image, id, description, colors, video_small} = themeData;
    let link = `/themenwelten/${id}`;
    let videoPlay = false;

    function playVideo(e) {
        let video = e.currentTarget.querySelector('video');
        if (video && videoPlay === false) {
            video.play().then(() =>
                videoPlay = true
            );
        }
    }

    function stopVideo(e) {
        let video = e.currentTarget.querySelector('video');
        if (video && videoPlay === true) {
            video.pause();
            videoPlay = false;
        }
    }

    function Media() {
        if (!isMobile) {
            return (
                <video loop muted poster={image}>
                    <source src={video_small}/>
                    {/*video fallback*/}
                    <LazyLoadImage
                        src={image}
                        alt={decode(title)}
                        effect="blur"
                    />
                </video>
            )
        } else {
            return (
                <LazyLoadImage
                    src={image}
                    alt={decode(title)}
                    effect="blur"
                />
            )
        }
    }

    return (
        <div className="theme-list-item col-md-6">
            <div
                className="card theme-card"
                onMouseOver={playVideo}
                onMouseOut={stopVideo}
            >
                <div className="card-img">
                    <Media/>
                </div>
                <div className="card-content">
                    <div className="theme-list-item-title-wrapper">
                        <h2 className="theme-list-item-title card-title h3">{title}</h2>
                        <div className="theme-item-colors">
                            {Object.values(colors).map((value, index) =>
                                <span className="theme-item-color" style={{backgroundColor: value}} key={index}/>
                            )}
                        </div>
                    </div>
                    <div>
                        <p>{description}</p>
                    </div>
                    <div>
                        <Link to={link}>
                            <button className="btn btn-outline-primary">
                                Kollektion ansehen
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ThemesItem;