import MaterialListItem from "./MaterialListItems";
import InfiniteScroll from "react-infinite-scroll-component";
import React, {useEffect, useState} from "react";
import axios from "axios";
import ThemeSelect from "../Themes/ThemeSelect";
import Loader from './../../assets/img/loader.svg';
import {useLocation} from "react-router-dom";

function MaterialList(props) {
    let {themeListId} = props;
    let defaultOrder = 'name';
    const {pathname} = useLocation();

    if (pathname === '/neuste') {
        defaultOrder = 'date';
    }

    const [hasMoreData, setHasMoreData] = useState(true);
    const [materialData, setMaterialData] = useState([]);
    const itemsPerPage = 4;
    const [maxItems, setMaxItems] = useState();
    const [page, setPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [order, setOrder] = useState(defaultOrder);
    const [isLoading, setIsLoading] = useState();

    useEffect(() => {
        setPage(2);
        setHasMoreData(true);
        searchMaterials();
    }, [themeListId, order, searchTerm]);

    function searchMaterials() {
        axios.get(`${process.env.REACT_APP_API_DOMAIN}/wp-json/sb-api/v1/materials/all?&per_page=${itemsPerPage}&search=${searchTerm}&themeid=${themeListId}&orderby=${order}`)
            .then(response => response.data)
            .then((data) => {
                setMaxItems(data.dataLength);
                setMaterialData(data.data);
                setIsLoading(false);

                if (data.dataLength !== 0 && materialData.length < data.dataLength) {
                    setPage(2);
                    setHasMoreData(true);
                }
            });
    }

    // get data for infinite scrolling
    function fetchData() {
        if (materialData.length >= maxItems) {
            setHasMoreData(false);
            return;
        }
        setPage(page + 1);
        setTimeout(() => {
            axios.get(`${process.env.REACT_APP_API_DOMAIN}/wp-json/sb-api/v1/materials/all?&per_page=${itemsPerPage}&page=${page}&search=${searchTerm}&themeid=${themeListId}&orderby=${order}`)
                .then(response => response.data)
                .then((data) => {
                    setMaterialData(materialData.concat(data.data));
                });
        }, 200);
    }

    function handleSearch(event) {
        setSearchTerm(event.target.value);
    }

    function handleSort(event) {
        setOrder(event.target.value);
    }

    function handleThemeFilter(themeIdProp) {
        themeListId = themeIdProp;
        props.updateThemeId(themeIdProp);
    }

    return (
        <div className="mb-5">
            <div className="search-form">
                <div className="search-form-inner row">
                    <div className="col-lg-8 col-md-7 col-sm-6 col-12 mb-3 mb-sm-0">
                        <div className="search-form-input-group input-group">
                            <span className="input-group-text"><i className="icon icon-search-1"></i></span>
                            <input
                                className="search-form-input form-control"
                                type="text"
                                placeholder="Materialsuche"
                                value={searchTerm}
                                onChange={handleSearch}
                            />
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-4 col-10">
                        <select
                            className="form-select"
                            aria-label="select theme"
                            onChange={handleSort}
                            defaultValue={defaultOrder}
                        >
                            <option value="date">Neuste zuerst</option>
                            <option value="name">Alphabetisch</option>
                        </select>
                    </div>
                    <div className="col-sm-1 col-2">
                        <ThemeSelect handleThemeFilter={handleThemeFilter} themeId={themeListId}/>
                    </div>
                </div>
            </div>
            {materialData.length > 0 &&
            <InfiniteScroll
                dataLength={materialData.length}
                next={fetchData}
                className="materials"
                hasMore={hasMoreData}
                loader={<div className="loader"><img src={Loader} alt="Laden"/></div>}
            >
                {materialData.map(material =>
                    <MaterialListItem key={material.id} material={material} themeId={themeListId}/>
                )}
            </InfiniteScroll>
            }
            {materialData.length === 0 && isLoading === false &&
            <div className="materials materials-not-found">
                <div className="text-center">
                    Keine Materialkombination vorhanden.
                </div>
            </div>
            }
        </div>
    )
}

export default MaterialList;