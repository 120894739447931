import React, {useEffect, useState} from 'react';
import axios from "axios";
import {decode} from 'html-entities';
import MaterialList from "../Materials/MaterialList";
import {Link, useParams} from "react-router-dom";
import Audio from "../Elements/Audio";

function AllMaterials() {
    let {themeId} = useParams();
    if (!themeId) {
        themeId = process.env.REACT_APP_PARENT_THEME_ID
    }
    const [themeData, setThemeData] = useState([]);
    const [themeTitle, setThemeTitle] = useState();
    const [isLoading, setIsLoading] = useState();

    useEffect(() => {
        getTheme(themeId);
    }, [themeId]);

    function getTheme() {
        setIsLoading(true);
        axios.get(`${process.env.REACT_APP_API_DOMAIN}/wp-json/sb-api/v1/theme/${themeId}`)
            .then(response => response.data)
            .then((data) => {
                setThemeData(data);
                setThemeTitle(data.title)
                setIsLoading(false);
            });
    }

    function updateThemeId(themeIdProp) {
        themeId = themeIdProp;
    }

    function Video() {
        if (themeData.video && isLoading === false) {
            return (
                <div className="material-hero-img hero-img">
                    <video autoPlay loop muted playsInline poster={themeData.image}>
                        <source src={themeData.video}/>
                        Your browser does not support the video tag.
                    </video>
                </div>
            )
        }
    }

    function Intro() {
        if (themeTitle) {
            if (themeId === process.env.REACT_APP_PARENT_THEME_ID) {
                return (
                    <div className={`intro loading-${isLoading}`}>
                        <div className="intro-inner">
                            <h1>{decode(themeTitle)}</h1>
                            <div className="intro-text">
                                <p>{decode(themeData.description)}</p>
                            </div>
                        </div>
                    </div>
                )
            } else {
                return (
                    <div className={`intro loading-${isLoading}`}>
                        <div className="intro-inner">
                            <Audio audioSrc={themeData.audio}/>
                            <h1>Themenwelt „{decode(themeTitle)}“</h1>
                            <div className="intro-text">
                                <p>{decode(themeData.description)}</p>
                            </div>
                            <Link
                                className="link back-link"
                                to="/themenwelten">
                                <i className="icon icon-arrow-left"></i> <span>Zur Themenwelt-Übersicht</span>
                            </Link>
                        </div>
                    </div>
                )
            }
        }
    }

    if (isLoading === false) {
        return (
            <div className="materials-list">
                <Video/>
                <div className="container">
                    <Intro/>
                    <MaterialList themeListId={themeId} updateThemeId={updateThemeId}/>
                </div>
            </div>
        );
    }
}

export default AllMaterials;