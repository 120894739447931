import React from 'react';

function SoundOnOff(props) {
    const title = props.title || "Musik an / aus ";
    const {state} = props;
    const css = `.nc-sound-on-off{--transition-duration:0.3s}.nc-sound-on-off :nth-child(5){transition:opacity 0s calc(.58 * var(--transition-duration))}.nc-sound-on-off :nth-child(6){transition:opacity 0s calc(.45 * var(--transition-duration))}.nc-sound-on-off :last-child{opacity:0;transition:stroke-dashoffset var(--transition-duration) cubic-bezier(.77,0,.18,1),opacity 0s var(--transition-duration)}.nc-sound-on-off.nc-int-icon-state-b :last-child{transition:stroke-dashoffset var(--transition-duration) cubic-bezier(.77,0,.18,1),opacity 0s;opacity:1;stroke-dashoffset:0}.nc-sound-on-off.nc-int-icon-state-b :nth-child(5),.nc-sound-on-off.nc-int-icon-state-b :nth-child(6){opacity:0}.nc-sound-on-off.nc-int-icon-state-b :nth-child(5){transition-delay:calc(.4 * var(--transition-duration))}.nc-sound-on-off.nc-int-icon-state-b :nth-child(6){transition-delay:calc(.5 * var(--transition-duration))}`;
    let muteClass = '';

    if (state === false) {
        muteClass = 'nc-int-icon-state-b';
    }

    function handleClick(e) {
        let group = e.currentTarget.querySelector('.js-nc-int-icon');
        if (!group) return;
        group.classList.toggle('nc-int-icon-state-b');
        e.currentTarget.dispatchEvent(new Event('ncstatechanged'));
    }

    return (
        <svg onClick={handleClick} height="32" width="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <title>{title}</title>
            <g fill="#c3c3c3" strokeWidth="1">
                <g className={`js-nc-int-icon nc-sound-on-off ${muteClass}`}>
                    <polyline fill="none" points="19 19 19 4 11 10 10 10" stroke="#c3c3c3" strokeMiterlimit="10"/>
                    <path d="M5.9,10H5a3,3,0,0,0-3,3v6a3,3,0,0,0,3,3h6l8,6V23.083" fill="none" stroke="#c3c3c3"
                          strokeLinecap="square" strokeMiterlimit="10"/>
                    <path d="M27,9.755a8,8,0,0,1,0,12.49" fill="none" stroke="#c3c3c3" strokeLinecap="square"
                          strokeMiterlimit="10"/>
                    <path d="M24,13.764a3,3,0,0,1,0,4.472" fill="none" stroke="#c3c3c3" strokeLinecap="square"
                          strokeMiterlimit="10"/>
                    <line fill="none" stroke="#c3c3c3" strokeMiterlimit="10" x1="10" x2="5.896" y1="10" y2="10"/>
                    <line fill="none" stroke="#c3c3c3" strokeMiterlimit="10" x1="19" x2="19" y1="23.083" y2="19"/>
                    <line fill="none" stroke="#c3c3c3" strokeDasharray="36.77 36.77" strokeLinecap="square" x1="3"
                          x2="29" y1="3" y2="29"/>
                </g>
                <style>{css}</style>
            </g>
        </svg>
    );
}

export default SoundOnOff;