import SoundOnOff from "./Speaker";
import ReactAudioPlayer from "react-audio-player";
import React, {useEffect, useState} from "react";
import {useCookies} from "react-cookie";
import {isIPhone13} from "react-device-detect";

function Audio(props) {
    const {audioSrc} = props;
    const [cookies, setCookie] = useCookies(['audio']);
    let playAudio = true;
    let audio;
    const [autoPlay, setAutoplay] = useState(true);

    if (cookies.audio === undefined) {
        setCookie('audio', true, {path: '/'});
    }

    // set audio state on init and update based on cookie value
    useEffect(() => {
        if (cookies.audio === 'false' || isIPhone13) {
            setAutoplay(false);
            playAudio = false;
        } else {
            setAutoplay(true);
            playAudio = true;
        }
    }, [cookies, autoPlay])

    // toggle audio play/pause and store info in cookie
    function toggleAudio(e) {
        audio = e.currentTarget.querySelector('audio');
        let audioPromise = audio.play();
        audioPromise.then(_ => {
            if (playAudio === true) {
                audio.pause();
                playAudio = false;
            } else {
                audio.play();
                playAudio = true;
            }

            setCookie('audio', playAudio, {path: '/'});
        })
    }

    function Player() {
        if (audioSrc) {
            return (
                <div className="audio-wrapper">
                    <div
                        className={`audio-button ${playAudio}`}
                        onClick={toggleAudio}
                    >
                        <SoundOnOff state={autoPlay}/>
                        <ReactAudioPlayer
                            src={audioSrc}
                            autoPlay={autoPlay}
                            loop
                            volume={0.8}
                        />
                    </div>
                </div>

            )
        }
    }

    return (
        <Player/>
    )
}

export default Audio;